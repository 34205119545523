import React, { useEffect, useState } from "react";
import { FaHeart, FaMinus, FaPlus, FaStar } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import { toast } from "react-toastify";
import ProductDetailsDrawer from "./ProductDetailsDrawer";
import SideDrawer from "../Header/SideDrawer";

function StarRating({ rating }) {
  return (
    <ul className="font-medium text-amber-400 flex gap-2 list-none">
      {Array.from({ length: 5 }, (_, index) => (
        <li className="inline" key={index}>
          <FaStar color={index < rating ? "gold" : "gray"} />
        </li>
      ))}
    </ul>
  );
}

const Product = ({ item, setupdate,setRefresh,setWishList,type,onRefresh}) => {

  console.log(onRefresh);
  
    const [isOpen, setIsOpen] = useState(false);
    const [cartDetails, setCartDetails] = useState({});
    const [allProduct, setAllProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [openProduct, setOpenProduct] = useState(false);
    const [checkState, setCheckState] = useState(false);
    const [quantityState, setStateQuantity] = useState(false);
  

  const storedUser = localStorage.getItem("user_ID") || undefined;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


   const refetchCartData = async () => {
    try {
      const response = await axios.get(
        `${Base_url}/cart/checkProd?productId=${item?.id}&userId=${storedUser}`
      );
      if (response?.data?.available === false) {
        setCheckState(false);
      } else {
        setCheckState(true);
        setCartDetails(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching cart details:", error);
    }
  };

  

  const handleRefresh = () => {
    refetchCartData();
  };

  useEffect(()=>{

    axios
    .get(`${Base_url}/cart/checkProd?productId=${item?.id}&userId=${storedUser}`)
    .then((res) => {
      console.log(res, "==============>>>>>>>>>sub check stateus");

      if (res?.data?.available === false) {
        setCheckState(false);
      } else {
        setCheckState(true);
        setCartDetails(res?.data?.data);
      }
    })
    .catch((error) => {});

  },[])

  const [isLiked, setIsLiked] = useState(
    item?.like?.includes(storedUser?.toString())
  );

  

  const handleLikeDislike = async () => {
    // if(!storedUser){
    //   toast.error('Please register your account');
    //   navigate('/login');
    // }else{

    try {
      setIsLiked(!isLiked);

      console.log(item?.id);

      const param = {
        id: item.id,
        userId: storedUser?.toString(),
      };

      const response = await axios.post(`${Base_url}/product/wishlist`, param);

      if (response.data.status === "ok") {
        toast.success(response?.data?.message);
        // setRefresh();
        if (setWishList || onRefresh) {
          setWishList();
          onRefresh();
        }

        


       
      }

      if (response.data.status === "fail") {
        toast.error(response?.data?.message);
        setIsLiked(!isLiked);
      
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }

    // }
  };

  
   
  const handleUpdateQuantity = async (type, cid, quantity) => {
    setLoading(true);
    const newQuantity = type === "increment" ? quantity + 1 : quantity - 1;

    if (newQuantity < 1) {
      toast.error("Quantity cannot be less than 1");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(`${Base_url}/cart/update/${cid}`, {
        quantity: newQuantity,
        totalSellPrice: Number(item?.sellPrice) * Number(newQuantity),
        totalOriginalPrice:
          Number(item?.specialPrice?item?.specialPrice:item?.originalPrice) * Number(newQuantity),
      });

      if (response.data.status === "ok") {
        setQuantity(newQuantity);
        // toast.success("Quantity updated successfully");

        axios
          .get(
            `${Base_url}/cart/checkProd?productId=${item?.id}&userId=${storedUser}`
          )
          .then((res) => {
            console.log(res, "==============>>>>>>>>>sub check stateus");

            if (res?.data?.available === false) {
              setCheckState(false);
            } else {
              setCheckState(true);
              setCartDetails(res?.data?.data);
            }
          })
          .catch((error) => {});
      } else {
        toast.error("Failed to update quantity");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("An error occurred while updating quantity");
    } finally {
      setLoading(false);
    }
  };


  const addFun = async () => {
    if (!storedUser) {
      toast.error("Please login to your account");
      navigate("/login");
    } else {
     

      try {
        setLoading(true);
        const params = {
          userId: storedUser,
          productId: item?.id,
          categoryId: item?.category?.id,
          subCategoryId: item?.subCategory?.id,
          subSubCategoryId: item?.subSubCategory?.id,
          deliveryCharge: item?.deliveryType === "Free" ? 0 : 12,
          quantity: item?.hasFreeProduct?quantity+1:quantity ,
          isBuy: true,
          totalSellPrice: Number(allProduct?.sellPrice) * Number(quantity),
          totalOriginalPrice:
            Number(item?.specialPrice?item?.specialPrice:item?.originalPrice) * Number(quantity),
          
        };

        const response = await axios.post(`${Base_url}/cart/create`, params);

        if (response.status === 200 || response.status === 201) {
          toast.success("Added to Cart Successfully!");
          setLoading(false);
          setStateQuantity(true);
          setOpenProduct(true);

          axios
            .get(
              `${Base_url}/cart/checkProd?productId=${item?.id}&userId=${storedUser}`
            )
            .then((res) => {
              console.log(res, "==============>>>>>>>>>sub check status");

              if (res?.data?.available === false) {
                setCheckState(false);
              } else {
                setCheckState(true);
                setCartDetails(res?.data?.data);
              }
            })
            .catch((error) => {
              console.error("Error fetching cart details:", error);
            });
        } else {
          setLoading(false);
          toast.error("Failed to add to cart. Please try again.");
        }
      } catch (error) {
        if (error?.response?.data?.status === "fail") {
          toast.error(error?.response?.data?.message);
          navigate("/login");
        }
        setLoading(false);
      }
    }
  };

  // <div className=" bg-white w-8 flex  hover:border hover:bg-gray-100 border-black shadow-md justify-center items-center h-8 rounded-full">
  // {isLiked ? (
  //     <FaHeart
  //       onClick={() => {
  //         handleLikeDislike(item._id);
  //       }}
  //     />
  //   ) : (
  //     <FaRegHeart
  //       onClick={() => {
  //         handleLikeDislike(item._id);
  //       }}
  //     />
  //   )}
  // </div>

  return (
    <>
      <div
        // key={item.id}
        className="group w-full bg-white"
      >
        <div className={`relative overflow-hidden shadow  sm:h-72 h-52 group-hover:shadow-lg group-hover::shadow-gray-800 rounded-md duration-500  ${type==='slider'?'w-[272px]':'w-full'} `}>
          <Link to={`/product_details/${item?.id}`} className="">
            <img
              src={item?.Image1}
              className="group-hover:scale-110  w-full h-full    object-contain duration-500"
              alt=""
            />
          </Link>
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
            {/* <Link
              className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
              to={`/product_details/${item?.id}`}
            >
               View Now
            </Link> */}
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
            <li>
              <div
                className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow cursor-pointer"
                onClick={handleLikeDislike}
              >
                <svg
                  stroke="currentColor"
                  fill={isLiked ? "black" : "none"}
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="size-4"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
                </svg>
              </div>
            </li>
            <li className="mt-1 ms-0">
              <Link
                to={`/product_details/${item?.id}`}
                className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="size-4"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                  <circle cx={12} cy={12} r={3} />
                </svg>
              </Link>
            </li>
            {/* <li className="mt-1 ms-0">
              <a
                className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                href="#"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="size-4"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                </svg>
              </a>
            </li> */}
          </ul>
          <ul className="list-none absolute top-[10px] start-4" />
          <ul className=" absolute top-2  left-2">
            {item?.tag === "0" ? null : (
              <li className=" bg-gray-800 shadow-xl flex text-sm justify-center items-center text-white rounded-md  w-20 h-6">
                {item?.tag}
              </li>
            )}
          </ul>
        </div>
        <div className="mt-4">
          <Link
            to={`/product_details/${item?.id}`}
            className="hover:text-orange-500  font-medium flex justify-start items-center flex-wrap text-wrap"
            href="/product-detail-one/9 "
          >
            {`${item?.name?.slice(0,30)}...`}
          </Link>
          <div className="flex flex-col justify-between  gap-2 mt-1">
            <p className="  flex gap-2">
              <span className=" flex items-center">
                <sup className=" font-semibold"> $ </sup>
                <div className=" flex">    
                <span className=" text-xl font-semibold">
                  { item?.specialPrice?item?.specialPrice:item?.originalPrice} 
                </span><sub className=" text-sm  pl-0.5 font-semibold">AUD</sub> 
                </div>
              </span>
              <div>
              {item?.specialPrice || item?.hasDiscount?<span className="bg-yellow-500 text-black font-bold text-[11px] px-1.5 py-1 rounded">
                  SAVE ${item?.originalPrice - item?.specialPrice} AUD
                </span>:null}
                
              </div>
            </p>
            <StarRating
              rating={item?.averageRating ? item?.averageRating : 0}
            />
            <p className=" text-xs uppercase font-medium">
              {item?.deliveryType === "Free"
                ? "Free Delivery"
                : "Standard Delivery"}
            </p>
            <div className="">

            <ProductDetailsDrawer setWishList={setWishList} setRefresh={handleRefresh} getData={item} isOpen={isOpen} setIsOpen={setIsOpen} />
            <SideDrawer isOpen={openProduct} setIsOpen={setOpenProduct} />
               {
                item?.Status === "Out Of Stock"?
                (
                 <>
                  <button
                        
                        className="py-1.5 bg-black px-5 inline-block opacity-50 cursor-not-allowed   font-medium tracking-wide align-middle duration-500 text-sm text-center  text-white border  border-black rounded-sm"
                        >
                        
                        Out Of Stock 
                       
                      </button>
                 </>
                ):(
                  checkState === true ? (
                    <>
                      <div className=" flex w-40  items-center">
                        <button
                          onClick={() =>
                            handleUpdateQuantity(
                              "decrement",
                              cartDetails?.id,
                              cartDetails?.quantity
                            )
                          }
                          disabled={loading}
                          className=" flex justify-center   text-white items-center w-20 h-10  bg-primary"
                        >
                          <FaMinus />
                        </button>
                        <input
                          value={cartDetails?.quantity}
                          className=" text-center border w-full outline-none  p-2"
                        />
                        <button
                          onClick={() =>
                            handleUpdateQuantity(
                              "increment",
                              cartDetails?.id,
                              cartDetails?.quantity
                            )
                          }
                          disabled={loading}
                          className=" flex justify-center   text-white items-center w-20 h-10  bg-primary"
                        >
                          <FaPlus />
                        </button>
                      </div>
                    </>
                  ) : (
                    item?.colorName?.length>0?
                      (
                        <button

                         
                        onClick={()=>setIsOpen(true)}
                        className="py-1.5 px-5 inline-block font-medium tracking-wide align-middle duration-500  text-sm text-center hover:bg-black hover:text-white  text-black border  border-black rounded-sm"
                        >
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <svg
                              className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                              ></path>
                            </svg>
                            Loading...
                          </div>
                        ):(
                          "Select options"
                        )}
                      </button>
                      ):
                      (
                        <button
                        onClick={addFun}
                        className="py-1.5 px-5 inline-block   font-medium tracking-wide align-middle duration-500 text-sm text-center hover:bg-black hover:text-white  text-black border  border-black rounded-sm"
                        >
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <svg
                              className="animate-spin h-6 w-6 text-gradient-to-r from-blue-500 to-purple-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v4a4 4 0 100 8h4a8 8 0 01-8 8z"
                              ></path>
                            </svg>
                            Loading...
                          </div>
                        ):(
                          "Add to Cart"
                        )}
                      </button>
                      )
                    
                   
                  )
                )
               }
              
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Product;
