import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import { toast } from "react-toastify";
import Button from "../../../components/Button";

const MyProfile = () => {
  const storedUser = localStorage.getItem("user_ID");
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phone: ""
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`${Base_url}/user/get/${storedUser}`);
        setUser(res.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user data");
      }
    };
    
    fetchUserData();
  }, [storedUser]);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const formData = new FormData(e.target);
    const updatedFields = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      username: formData.get("username"),
      email: formData.get("email")
    };
  
    // Only include fields that have changed
    const changes = {};
    Object.keys(updatedFields).forEach(key => {
      if (updatedFields[key] !== user[key]) {
        changes[key] = updatedFields[key];
      }
    });
  
    // Don't send request if nothing changed
    if (Object.keys(changes).length === 0) {
      toast.info("No changes detected");
      setLoading(false);
      return;
    }
  
    try {
      const res = await axios.put(
        `${Base_url}/user/update/${storedUser}`,
        changes
      );

      setLoading(false);
  
      if (res.status === 200) {
        setUser(prev => ({ ...prev, ...changes }));
        toast.success("Profile updated successfully!");
        // window.location.reload();
      }
    } catch (error) {
      console.error("Update error:", error);
      toast.error(error.response?.data?.message || "Error updating profile");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">My Account</h1>
      <form onSubmit={handleProfileUpdate} className="space-y-6">
        <h4 className="text-xl font-semibold">Edit Profile</h4>

        <div className="flex flex-wrap gap-4">
          <div className="w-full md:w-1/3">
            <Input
              placeholder="First Name"
              name="firstName"
              label="First Name"
              className="border w-full"
              defaultValue={user.firstName}
              required
            />
          </div>

          <div className="w-full md:w-1/3">
            <Input
              placeholder="Last Name"
              name="lastName"
              label="Last Name"
              className="border w-full"
              defaultValue={user.lastName}
              required
            />
          </div>

          <div className="w-full md:w-1/3">
            <Input
              placeholder="Username"
              name="username"
              label="Username"
              className="border w-full"
              defaultValue={user.username}
              required
            />
          </div>
          <div className="w-full md:w-1/3">
            <Input
              placeholder="Email"
              name="email"
              label="email"
              className="border w-full"
              defaultValue={user.email}
              required
            />
          </div>

          <div className="w-full md:w-1/3">
            <Input
              placeholder="Phone"
              name="phone"
              label="Phone"
              className="border w-full"
              defaultValue={user.phone}
              disabled
            />
          </div>

          <div className="w-full flex justify-end mt-4">
            <Button
              type="submit"
              label={loading ? "Updating..." : "Update Profile"}
              className="bg-black text-white py-3 px-6 rounded"
              disabled={loading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyProfile;