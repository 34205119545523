import axios from "axios";
import React, { useEffect, useState } from "react";
import { Base_url } from "../../../utils/Base_url";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Input from "../../../components/Input";
import { FaSearch, FaTimes } from "react-icons/fa";

const OrderHistory = () => {
  const storedUser = localStorage.getItem("user_ID") || undefined;

  const [orderHistory, setOrderHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const fetchOrderHistory = (page) => {
    setIsSearching(search !== "");
    axios
      .get(
        `${Base_url}/order/get?userId=${storedUser}&page=${page}&limit=${pageSize}&id=${search}`
      )
      .then((res) => {
        console.log(res);
        setOrderHistory(res.data.data);
        setTotalPages(res.data.totalPages);
      })
      .catch((error) => {
        console.error("Failed to fetch order history:", error);
      });
  };

  useEffect(() => {
    fetchOrderHistory(currentPage);
  }, [currentPage, search]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const updateStatus = (id, newStatus, page) => {
    const params = {
      status: newStatus,
    };
  
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to update the status of this order. Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`${Base_url}/order/update/${id}`, params)
          .then((res) => {
            if (res?.data?.status === "ok") {
              axios
                .get(
                  `${Base_url}/order/get?userId=${storedUser}&status=pending&page=${page}&limit=${pageSize}`
                )
                .then((res) => {
                  console.log(res);
                  setOrderHistory(res.data.data);
                  setTotalPages(res.data.totalPages);
                })
                .catch((error) => {
                  console.error("Failed to fetch order history:", error);
                });
              Swal.fire({
                title: 'Order Removed',
                text: 'Your order has been removed. You will receive a refund within 24 hours.',
                icon: 'success',
                confirmButtonText: 'OK'
              });
            } else {
              toast.error("Failed to update status. Please try again.");
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            toast.error("An error occurred while updating the status.");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your order status remains unchanged :)', 'error');
      }
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const clearSearch = () => {
    setSearch("");
    setCurrentPage(1);
  };

  const updatePaypalStatus = (id,page) => {
    const params = {
      payPalId:id,
    };
  
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to update the status of this order. Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Base_url}/order/refund`, params)
          .then((res) => {
            if (res?.data?.status === "ok") {
              axios
                .get(
                  `${Base_url}/order/get?userId=${storedUser}&status=pending&page=${page}&limit=${pageSize}`
                )
                .then((res) => {
                  console.log(res);
                  setOrderHistory(res.data.data);
                  setTotalPages(res.data.totalPages);
                })
                .catch((error) => {
                  console.error("Failed to fetch order history:", error);
                });
                Swal.fire({
                  title: 'Order Removed',
                  text: 'Your order has been removed. You will receive a refund within 24 hours.',
                  icon: 'success',
                  confirmButtonText: 'OK'
                });
            } else {
              toast.error("Failed to update status. Please try again.");
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            toast.error("An error occurred while updating the status.");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your order status remains unchanged :)', 'error');
      }
    });
  };

  return (
    <div className="container mx-auto">
      <section className="bg-white antialiased md:py-16">
        <div className="mx-auto max-w-screen-xl 2xl:px-0">
          <div className="mx-auto">
            <div className="gap-4 sm:flex sm:items-center sm:justify-between">
              <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                My orders
              </h2>

              <div className="relative flex items-center">
                <Input 
                  placeholder="Search..."
                  value={search}
                  label={'Search by Order ID:'}    
                  onChange={handleSearch}  
                  className={'w-60 border rounded-md pr-8'}
                />
                {search && (
                  <button
                    onClick={clearSearch}
                    className="absolute right-2 top-1/2 -translate-y-1/5 text-gray-500 hover:text-gray-700"
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            </div>

            <div className="mt-6 flow-root sm:mt-8">
              {orderHistory?.length > 0 ? (
                <div className="divide-y divide-gray-200">
                  {orderHistory?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-wrap items-center gap-y-4 py-6"
                    >
                      <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                        <dt className="text-base font-medium text-gray-500">
                          Order ID:
                        </dt>
                        <dd className="mt-1.5 text-base font-semibold text-gray-900">
                          <a href="#" className="hover:underline">
                            #{item.id}
                          </a>
                        </dd>
                      </dl>

                      <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                        <dt className="text-base font-medium text-gray-500">
                          Date:
                        </dt>
                        <dd className="mt-1.5 text-base font-semibold text-gray-900">
                          {moment(item.createdAt).format("MM-DD-YYYY")}
                        </dd>
                      </dl>

                      <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                        <dt className="text-base font-medium text-gray-500">
                          Price:
                        </dt>
                        <dd className="mt-1.5 text-base font-semibold text-gray-900">
                          ${item.totalBill} AUD
                        </dd>
                      </dl>

                      <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                        <dt className="text-base font-medium text-gray-500">
                          Status:
                        </dt>
                        <dd className="mt-1.5 capitalize text-base font-semibold text-gray-900">
                          {item.status}
                        </dd>
                      </dl>
                      <dl className="w-1/2 sm:w-1/4 lg:w-auto lg:flex-1">
                        <dt className="text-base font-medium text-gray-500">
                          Payment Status:
                        </dt>
                        <dd className="mt-1.5 text-base font-semibold text-gray-900">
                          {item.paymentStatus}
                        </dd>
                      </dl>
                      <div className="w-full grid sm:grid-cols-2 lg:flex lg:w-64 lg:items-center lg:justify-end gap-4">
                        <Link
                          to={`/order_details/${item?.id}`}
                          className="w-full inline-flex justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 lg:w-auto"
                        >
                          View details
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              ) : isSearching ? (
                <div className="flex flex-col items-center justify-center py-12">
                  <FaSearch className="text-4xl text-gray-400 mb-4" />
                  <p className="text-lg text-gray-600">No orders found matching your search</p>
                  <button
                    onClick={clearSearch}
                    className="mt-4 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors"
                  >
                    Clear search
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center py-12">
                  <p className="text-lg text-gray-600">No orders found</p>
                </div>
              )}
            </div>

            {orderHistory?.length > 0 && (
              <div className="flex justify-end gap-2 items-center p-4">
                <button
                  className="px-4 py-2 text-white bg-black rounded disabled:opacity-50"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <div className="flex items-center gap-4">
                  <p className="font-medium">
                    Page {currentPage} of {totalPages}
                  </p>
                  <select
                    className="px-2 py-1 border rounded"
                    value={currentPage}
                    onChange={(e) => handlePageChange(Number(e.target.value))}
                  >
                    {Array.from({ length: totalPages }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  className="px-4 py-2 text-white bg-black rounded disabled:opacity-50"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderHistory;